/* eslint-disable import/extensions, import/no-unresolved, import/no-unassigned-import, import/no-extraneous-dependencies */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SideNav from 'components/SideNav';
import Main from './Main';
import TopNav from '../TopNav';
import PageHeader from '../PageHeader';
import Query from '../../queries/navQuery';
import { Box } from 'CHAMELEON_REACT_HUB';
import Content from './Content';
import BrandThemeProvider from './BrandThemeProvider';
import Container from '../Container';

const Layout = ({ children, pageContext, location }) => {
  const frontmatter = pageContext && pageContext.frontmatter;
  return (
    <Query
      render={menuItems => (
        <BrandThemeProvider>
          <TopNav />
          <Main>
            <Container pt={11}>
              <div>
                {frontmatter && (
                  <PageHeader
                    title={frontmatter.title}
                    location={location}
                    menuItems={menuItems}
                    pathName={location.pathname}
                    parent={frontmatter.name}
                  />
                )}
              </div>
              <Content>
                <Box my={7}>{children}</Box>
              </Content>
            </Container>
          </Main>
          {/** SideNav must come after main for z-index */}
          <SideNav
            location={location}
            pageContext={pageContext}
            menuItems={menuItems}
          />
        </BrandThemeProvider>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
    protocol: PropTypes.string,
    search: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    frontmatter: PropTypes.shape({
      name: PropTypes.string,
      menu: PropTypes.string,
    }),
  }),
};

export default Layout;
/* eslint-enable import/extensions, import/no-unresolved, import/no-unassigned-import */
