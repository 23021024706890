import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import List from 'components/LinkCard/List';
import ListItem from 'components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "ios-plist",
      "style": {
        "position": "relative"
      }
    }}>{`iOS Plist`}</h3>
    <p>{`We updated the plist structure to support italic fonts, and provided better
falbacks, besides this change, the structure also changed.`}</p>
    <h3 {...{
      "id": "sunday-world",
      "style": {
        "position": "relative"
      }
    }}>{`Sunday world`}</h3>
    <p>{`We made some of the last changes for the Sunday World theme, after receiving
feedback.`}</p>
    <ul>
      <li parentName="ul">{`Choice checked background changed`}</li>
      <li parentName="ul">{`Stepper step color is changed`}</li>
      <li parentName="ul">{`Illustrations are updated`}</li>
      <li parentName="ul">{`Loader color is changed`}</li>
    </ul>
    <h3 {...{
      "id": "de-limburger",
      "style": {
        "position": "relative"
      }
    }}>{`De Limburger`}</h3>
    <p>{`We updated the De Limburger main and plus logos. Make sure you're using the
following logos:`}</p>
    <ul>
      <li parentName="ul">{`brand-plus -> brand-plus-main`}</li>
      <li parentName="ul">{`brand-rectangle-name -> brand-main`}</li>
      <li parentName="ul">{`brand-square-icon -> brand-square-main`}</li>
    </ul>
    <p>{`For more info about migration go to the `}<a parentName="p" {...{
        "href": "/logo-migration"
      }}>{`logo migration guide`}</a>{`.`}</p>
    <h3 {...{
      "id": "het-belang-van-limburg",
      "style": {
        "position": "relative"
      }
    }}>{`Het Belang Van Limburg`}</h3>
    <p>{`For usage in the app header, there is a new logo for hbvl introduced.`}</p>
    <ul>
      <li parentName="ul">{`brand-icon-abbreviation-horizontal`}</li>
    </ul>
    <h3 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}>{`What's next?`}</h3>
    <p>{`We're restructuring the way the design system team works to make sure our
roadmap is better aligned with TPS prios. We're planning to work closer with
other clusters, more info about this later on!`}</p>
    <h3 {...{
      "id": "full-changelog",
      "style": {
        "position": "relative"
      }
    }}>{`Full changelog`}</h3>
    <p>{`Check out the full changelog for more information.`}</p>
    <List my={8} mdxType="List">
  <ListItem title="v1.10.0 changelog" description="Get a full overview of what's changed in the changelog" to="/updates/changelog" mdxType="ListItem" />
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      